<template>
  <div>
    <v-card>
      <v-card-text class="mb-2">
        <v-select
          v-model="product_type_id"
          :items="product_types"
          @change="select_pattern"
          item-text="product_type_name"
          item-value="product_type_id"
          label="ประเภทของลายร้าน"
        ></v-select>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-text>
        <v-row>
          <v-col
            v-if="pattern_set_prices && pattern_set_prices.length > 0"
            cols="12">
            <table class="table">
              <thead>
              <tr>
                <th width="50" class="text-left">#</th>
                <th class="text-left">ประเภทของลายร้าน</th>
                <th width="250">*ราคาพิเศษสำหรับลายร้าน</th>
                <th width="100" class="text-center">เครื่องมือ</th>
              </tr>
              </thead>
              <tbody>
              <!--            pattern_set_prices-->
              <tr v-for="(item,index) in pattern_set_prices">
                <td> {{ index + 1 }}</td>
                <td>
                  {{ item.product_type ? item.product_type.product_type_name : '' }}
                </td>
                <td>
                  <v-text-field
                    v-model="item.price"
                    type="number"
                    min="0"
                    placeholder="ราคาพิเศษสำหรับลายร้าน"
                  ></v-text-field>
                </td>
                <td class="text-center">
                  <v-icon
                    @click="deleteItem(index)"
                    class="mr-2"
                    small
                    color="danger"
                  >

                    {{ icons.mdiDelete }}
                  </v-icon>
                </td>
              </tr>
              </tbody>
            </table>
            <hr class="mb-5" style="border: 0.5px solid lightgrey">
            </v-col>
          <v-col
            cols="12">

            <v-btn
              style="width: 100%"
              @click="save"
              color="primary">
              บันทึก
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>


    </v-card>
  </div>
  <!--  <v-data-table-->
  <!--    :headers="headers"-->
  <!--    :items="transport_types"-->
  <!--    :search="search"-->
  <!--    sort-by="calories"-->
  <!--    class="elevation-1"-->
  <!--  >-->
  <!--    <template v-slot:top>-->
  <!--      <v-toolbar flat>-->
  <!--        &lt;!&ndash; <v-toolbar-title> &ndash;&gt;-->
  <!--        <v-text-field-->
  <!--          v-model="search"-->
  <!--          label="Search"-->
  <!--          single-line-->
  <!--          hide-details-->
  <!--        ></v-text-field>-->
  <!--        &lt;!&ndash; </v-toolbar-title> &ndash;&gt;-->
  <!--        &lt;!&ndash; <v-divider class="mx-4" inset vertical></v-divider> &ndash;&gt;-->
  <!--        <v-spacer></v-spacer>-->
  <!--        <v-dialog-->
  <!--          v-model="dialog"-->
  <!--          max-width="500px"-->
  <!--        >-->
  <!--          <template v-slot:activator="{ on, attrs }">-->
  <!--            &lt;!&ndash; <div class="d-flex justify-space-between"> &ndash;&gt;-->
  <!--            &lt;!&ndash; <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> &ndash;&gt;-->
  <!--            <v-btn-->
  <!--              color="primary"-->
  <!--              dark-->
  <!--              class="mb-2"-->
  <!--              v-bind="attrs"-->
  <!--              v-on="on"-->
  <!--            >-->
  <!--              เพิ่มรูปแบบการจัดส่ง-->
  <!--            </v-btn>-->
  <!--            &lt;!&ndash; </div> &ndash;&gt;-->
  <!--          </template>-->
  <!--          <v-card>-->
  <!--            <v-card-title>-->
  <!--              <span class="text-h5">{{ formTitle }}</span>-->
  <!--            </v-card-title>-->

  <!--            <v-card-text>-->
  <!--              <v-container>-->
  <!--                <v-row>-->
  <!--                  <v-col-->
  <!--                    cols="12"-->
  <!--                    sm="12"-->
  <!--                    md="12"-->
  <!--                  >-->
  <!--                    <v-text-field-->
  <!--                      v-model="editedItem.transport_type_name"-->
  <!--                      label="รูปแบบการจัดส่ง"-->
  <!--                    ></v-text-field>-->
  <!--                  </v-col>-->
  <!--                  <v-col-->
  <!--                    cols="12"-->
  <!--                    sm="12"-->
  <!--                    md="12"-->
  <!--                  >-->
  <!--                    <v-text-field-->
  <!--                      v-model="editedItem.url"-->
  <!--                      label="ลิ้งค์ติดตามพัสดุ"-->
  <!--                    ></v-text-field>-->
  <!--                  </v-col>-->
  <!--                  <v-col-->
  <!--                    cols="12"-->
  <!--                    sm="12"-->
  <!--                    md="12">-->
  <!--                    <v-checkbox-->
  <!--                      value="Y"-->
  <!--                      v-model="editedItem.promotion"-->
  <!--                      label="โปรโมชั่นส่งฟรี"-->
  <!--                    ></v-checkbox>-->
  <!--                  </v-col>-->

  <!--                  <v-col-->
  <!--                    cols="12"-->
  <!--                    sm="12"-->
  <!--                    md="12">-->
  <!--                    <DatePicker-->
  <!--                      placeholder="จากวันที่"-->
  <!--                      v-model="editedItem.start_date"-->
  <!--                      label="จากวันที่"-->
  <!--                    ></DatePicker>-->
  <!--                  </v-col>-->

  <!--                  <v-col-->
  <!--                    cols="12"-->
  <!--                    sm="12"-->
  <!--                    md="12">-->
  <!--                    <DatePicker-->
  <!--                      placeholder="ถึงวันที่"-->
  <!--                      v-model="editedItem.end_date"-->
  <!--                      label="ถึงวันที่"-->
  <!--                    ></DatePicker>-->
  <!--                  </v-col>-->
  <!--                </v-row>-->
  <!--              </v-container>-->
  <!--            </v-card-text>-->

  <!--            <v-card-actions>-->
  <!--              <v-spacer></v-spacer>-->
  <!--              <v-btn-->
  <!--                color="error"-->
  <!--                text-->
  <!--                @click="close"-->
  <!--              >-->
  <!--                Cancel-->
  <!--              </v-btn>-->
  <!--              <v-btn-->
  <!--                color="primary"-->
  <!--                text-->
  <!--                @click="save"-->
  <!--              >-->
  <!--                Save-->
  <!--              </v-btn>-->
  <!--            </v-card-actions>-->
  <!--          </v-card>-->
  <!--        </v-dialog>-->
  <!--        <v-dialog-->
  <!--          v-model="dialogDelete"-->
  <!--          max-width="600px"-->
  <!--        >-->
  <!--          <v-card>-->
  <!--            <v-card-title class="text-h5 mb-5">-->
  <!--              Are you sure you want to delete this item?-->
  <!--            </v-card-title>-->
  <!--            <v-card-actions>-->
  <!--              <v-spacer></v-spacer>-->
  <!--              <v-btn-->
  <!--                color="error"-->
  <!--                outlined-->
  <!--                @click="closeDelete"-->
  <!--              >-->
  <!--                Cancel-->
  <!--              </v-btn>-->
  <!--              <v-btn-->
  <!--                color="primary"-->
  <!--                @click="deleteItemConfirm"-->
  <!--              >-->
  <!--                OK-->
  <!--              </v-btn>-->
  <!--              <v-spacer></v-spacer>-->
  <!--            </v-card-actions>-->
  <!--          </v-card>-->
  <!--        </v-dialog>-->
  <!--      </v-toolbar>-->
  <!--    </template>-->
  <!--    <template v-slot:item.index="{ index }">-->
  <!--      {{ index + 1 }}-->
  <!--    </template>-->
  <!--    <template v-slot:item.status="{ item }">-->
  <!--      <v-checkbox-->
  <!--        value="Y"-->
  <!--        @click="updateStatusPackage(item.package_id)"-->
  <!--        v-model="item.package_status">-->
  <!--      </v-checkbox>-->
  <!--    </template>-->
  <!--    <template v-slot:item.actions="{ item }">-->
  <!--      <v-icon-->
  <!--        small-->
  <!--        class="mr-2"-->
  <!--        @click="editItem(item)"-->
  <!--      >-->
  <!--        {{ icons.mdiPencil }}-->
  <!--      </v-icon>-->
  <!--      <v-icon-->
  <!--        small-->
  <!--        @click="deleteItem(item)"-->
  <!--      >-->
  <!--        {{ icons.mdiDelete }}-->
  <!--      </v-icon>-->
  <!--    </template>-->
  <!--    <template v-slot:item.promotion_free="{ item }">-->
  <!--      <span v-if="item.start_promotion && item.end_promotion">-->
  <!--        วันที่เริ่ม {{ item.start_promotion ? item.start_promotion : '-' }} <br>-->
  <!--        วันที่สิ้นสุด {{ item.end_promotion ? item.end_promotion : '-' }}-->
  <!--      </span>-->
  <!--      <span v-if="!item.start_promotion && !item.end_promotion">-->
  <!--        - -->
  <!--      </span>-->
  <!--    </template>-->

  <!--    <template v-slot:item.status1="{ item }">-->
  <!--      <v-checkbox-->
  <!--        value="Y"-->
  <!--        @click="UpdateStatus(item.transport_type_id,1)"-->
  <!--        v-model="item.transport_status"-->
  <!--      ></v-checkbox>-->
  <!--    </template>-->

  <!--    <template v-slot:item.status2="{ item }">-->
  <!--      <v-checkbox-->
  <!--        value="Y"-->
  <!--        @click="UpdateStatus(item.transport_type_id,2)"-->
  <!--        v-model="item.transport_expense_status"-->
  <!--      ></v-checkbox>-->
  <!--    </template>-->
  <!--    <template v-slot:item.status3="{ item }">-->
  <!--      <v-checkbox-->
  <!--        value="Y"-->
  <!--        @click="UpdateStatus(item.transport_type_id,3)"-->
  <!--        v-model="item.transport_setadd_status"-->
  <!--      ></v-checkbox>-->
  <!--    </template>-->
  <!--    <template v-slot:no-data>-->
  <!--      &lt;!&ndash; <v-btn-->
  <!--        color="primary"-->
  <!--        @click="initialize"-->
  <!--      >-->
  <!--        Reset-->
  <!--      </v-btn> &ndash;&gt;-->
  <!--    </template>-->
  <!--  </v-data-table>-->
</template>
<style>
table, th, td {
  /*border: 1px solid black;*/
  /*border-collapse: collapse;*/
  padding: 15px;
}
</style>
<script>
import {mdiPencil, mdiDelete} from '@mdi/js'
import DatePicker from 'vue2-datepicker'
// import config from '../../../config'
import instance from '../../../axios.service'
import instance_pattern_set_price from '../../../services/patternSetPrice'
import instance_product_type from '../../../services/productType'
import Swal from "sweetalert2";

export default {
  components: {DatePicker},
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    product_types: [],
    pattern_set_prices: [],
    product_type_id: null,
    product_type_ids: [],
    dialog: false,
    dialogDelete: false,
    search: '',
    headers: [
      {
        text: '#',
        value: 'index',
        sortable: false
      },
      {text: 'ประเภทของลายร้าน', value: 'name'},
      {text: '*ราคาพิเศษสำหรับลายร้าน', value: 'price'},
      {text: 'แก้ไขข้อมูล', value: 'actions', sortable: false},
    ],
    editedIndex: -1,
    editedItem: {
      transport_type_name: '',
      promotion: 'N',
      transport_status: '',
      transport_expense_status: '',
      transport_setadd_status: 0,
      start_date: null,
      end_date: null,
      url: '',
    },
    defaultItem: {
      transport_type_name: '',
      promotion: '',
      transport_status: '',
      transport_expense_status: '',
      transport_setadd_status: 0,
      start_date: null,
      end_date: null,
      url: '',
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม รูปแบบการจัดส่ง' : 'แก้ไข รูปแบบการจัดส่ง'
    },
  },

  watch: {
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    // this.getUser()
    this.getPatternSetPrice()
    this.getProductType()
  },

  methods: {
    select_pattern() {
      const index = this.product_types.findIndex(object => {
        return object.product_type_id === this.product_type_id;
      });

      var data = {
        id: '',
        employee_id: '',
        price: 0,
        product_type_id: this.product_types[index].product_type_id,
        product_type: this.product_types[index],
      };
      this.pattern_set_prices.push(data);
      this.product_types.slice(index, 1);

      this.product_type_id = null
    },
    deleteItem(index) {

      this.pattern_set_prices.splice(index, 1)
      // this.editedIndex = this.transport_types.indexOf(item)
      // this.editedItem = {...item}
      // this.dialogDelete = true
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    save() {
      this.$store.state.isLoading = true
      instance_pattern_set_price.update(this.pattern_set_prices)
        .then(res => {
            setTimeout(function () {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              })
            },150);
          this.getPatternSetPrice()
          this.close()
        }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false

      });
    },
    getPatternSetPrice() {
      this.$store.state.isLoading = true
      var data = {
        search: '',
        date: '',
        status: '',
      }

      instance_pattern_set_price.get(data).then(res => {
        this.pattern_set_prices = res.data.data;
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      });
    },
    getProductType() {
      this.$store.state.isLoading = true
      var data = {
        search: '',
        date: '',
        sortDesc: '',
        sortBy: '',
        status: '',
        page: 1,
        size: 99999999
      }

      instance_product_type.get(data).then(res => {
        this.product_types = res.data.data.data;
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      });
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
