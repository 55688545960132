import api from '../axios.service'

export default {
  get()
  {
    let params = new FormData();

    return api.get('pattern_set_prices/list',params,{})
  },
  // getAll()
  // {
  //   let params = new FormData();
  //
  //   return api.get('pattern_set_prices/all',params,{})
  // },

  update(data) {
    let getFormData = data
    console.log(getFormData)
    let params = new FormData();
    params.append('_method', 'PUT');
    for (var key in getFormData) {
      console.log(getFormData[key]);
      if (getFormData.hasOwnProperty(key)) {
        params.append('price[]', getFormData[key].price);
        params.append('product_type_id[]', getFormData[key].product_type_id);
        params.append('pattern_set_price_id[]', getFormData[key].id);
      }
    }
    return api.post(`pattern_set_prices/update/1`, params, {})
  },

  destroy(data) {
    let getFormData = data

    let params = new FormData();
    // params.append('_method', 'DELETE');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }

    return api.delete(`pattern_set_prices/delete/${data.pattern_id}`, params, {})
  },

}
